"use client";
// 走马灯接口参考https://react-swipeable-views.com/api/api/
import React, { useState, useMemo, useEffect } from "react";
import SwipeableViews, { SwipeableViewsProps } from "react-swipeable-views";
import { autoPlay, WithAutoPlayProps } from "react-swipeable-views-utils";
import { IStyleProps, NeverT } from "../../interface";
import classnames from "classnames";
import Pagination from "./Dot"; // 配置dot
import stl from "../../styles/carousel.module.scss";

// TODO: 用的走马灯组件还不能实现无限循环
interface IClasses {
    container: string;
    dots: boolean | string; // 点点点
}

interface IAutoPlay {
    autoplay?: boolean;
}

type AutoPlay = (IAutoPlay & WithAutoPlayProps) | NeverT<keyof IAutoPlay>;
interface IFmCarouselProps extends IStyleProps, SwipeableViewsProps {
    dots?: boolean; // 是否显示小点儿
    infinite?: boolean; // 这个暂时没用，无限循环播放的意思，存在于autoPlay为true时
    carouselClasses?: Partial<IClasses>;
}

type IProps = IFmCarouselProps & AutoPlay;

const defaultProps = {
    dots: false,
    infinite: false,
};
const FmSwipeableViews: React.FC<IProps> = (prop) => {
    const { infinite, onChangeIndex, index, carouselClasses, ...props } =
        useMemo(() => {
            return { ...defaultProps, ...prop };
        }, [prop]);
    const [currentIndex, setCurrentIndex] = useState(index ?? 0);

    useEffect(() => {
        setCurrentIndex(index ?? 0);
    }, [index]);
    const handleChangeIndex = (index: number) => {
        setCurrentIndex(index);
        onChangeIndex?.(index, React.Children.count(props.children));
    };

    return (
        <div
            style={props.style}
            className={classnames(
                stl["carousel-container"],
                "carousel-container",
                props.className,
            )}
        >
            <SwipeableViews
                index={currentIndex}
                onChangeIndex={handleChangeIndex}
                className={carouselClasses?.container}
                slideStyle={props.slideStyle}
                onSwitching={props.onSwitching}
                onDrag={props.onDrag}
            >
                {props.children}
            </SwipeableViews>
            <Pagination
                dots={React.Children.count(props.children)}
                index={currentIndex}
                className={classnames(
                    {
                        ["hidden"]: !props.dots,
                    },
                    carouselClasses?.dots,
                )}
                onChangeIndex={handleChangeIndex}
            />
        </div>
    );
};

const AutoPlaySwipeableViews = autoPlay(FmSwipeableViews);

const FmCarousel = (props: IProps) => {
    const { carouselClasses, ...rest } = props;
    return props.autoplay ? (
        <AutoPlaySwipeableViews
            {...rest}
            carouselClasses={{ dots: carouselClasses?.dots }}
            className={carouselClasses?.container}
        />
    ) : (
        <FmSwipeableViews {...props} />
    );
};

export default FmCarousel;
