"use client";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import UpdateForm from "./UpdateForm";
import { Formik, Form } from "formik";
import stl from "./GetCodeForm.module.scss";
import { getCodeFromEmail } from "@/src/models/common";
import { changeSubscription, isLogin } from "@/src/models/loginModel";
import { useCommonContext } from "@/src/provider";
import classnames from "classnames";

import { updateSub } from "@/store";
import variable from "@/src/styles/variables.module.scss";
import { useTranslation } from "@/src/i18n/client";
import {
    FmDrawer,
    FmToast,
    FmInput,
    FmCheckbox,
    FmLink,
    FmImage,
    FmButton,
} from "@/ui-component";

const url: Record<string, string> = {
    de: "/z/Anmelden-brillen.html",
    mx: "/lentes-los-mas-vendidos.html",
    pt: "/todos-os-produtos",
    cl: "/anteojos-opticos/todos.htm",
};

interface IProps {
    nosubscribe?: boolean;
    H5Subscription: any;
}

interface IFormikValue {
    email: string;
    password: string;
    sub: string; // 这里再斟酌一下,原来是要bool
    thanksModalOpen: boolean;
    user: Record<string, any>;
}

const GetCodeForm: React.FC<IProps> = (props) => {
    const W = useRef(false);
    const { H5Subscription = {} } = props;
    const { is_cms, site } = useCommonContext();
    const { t } = useTranslation(["common", "account"]);

    const [pathname, setPathname] = useState("");
    const [subscription, updateSubscription] = updateSub.useUpdateSubStore(
        (state) => [state.subscription, state.updateSubscription],
    ); // id对应文案的标记，除非改文案，否则无法更改

    const [modalStateOpen, setModalStateOpen] = useState(false);
    const openModal = useCallback(() => {
        setModalStateOpen(true);
    }, []);
    const closeModal = useCallback(() => {
        setModalStateOpen(false);
    }, []);

    useEffect(() => {
        setPathname(location.pathname);
    }, []);

    const getTime = () => {
        let now = Math.floor(Date.now() / 1000);
        let time1 = 1573293600 - now;
        let time2 = 1574668800 - now;
        let res = false;
        if (time1 <= 0 && time2 > 0) {
            res = true;
        }

        if (time2 < 0) {
            res = false;
        }

        return res;
    };

    const isShowTime = useMemo(() => getTime(), []);

    const { imgs = "", link = "", title = "", bg = "" } = H5Subscription;
    const [showToast, setShowToast] = useState("");
    const initialValues = useMemo<IFormikValue>(() => {
        return {
            email: "",
            password: "",
            sub: "", // 这里再斟酌一下,原来是要bool
            thanksModalOpen: false,
            user: {} as Record<string, any>,
        };
    }, []);

    const handleValidate = useCallback(
        (values: IFormikValue) => {
            let errors: Record<string, any> = {};
            if (!W.current) {
                return errors;
            }

            if (!values.email) {
                errors.email = t("common:Required");
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
                errors.email = t("common:InvalidEmailAddress");
            }
            return errors;
        },
        [t],
    );
    const handleSubmit = useCallback(
        async (
            values: IFormikValue,
            {
                setSubmitting,
                setValues,
                setErrors /* setValues, setStatus, and other goodies */,
            }: any,
        ) => {
            W.current = true;
            setSubmitting(true);
            getCodeFromEmail({ email: values.email })
                .then(
                    (user) => {
                        setValues({
                            ...values,
                            user,
                            thanksModalOpen: true,
                        });
                    },
                    (e) => {
                        setShowToast(e?.message);
                    },
                )
                .catch((err) => {
                    setShowToast(err?.message);
                })
                .finally(() => {
                    setSubmitting(false);
                    W.current = false;
                });

            if (props.nosubscribe || !isLogin()) {
                return;
            } else {
                try {
                    await changeSubscription({ subscription });
                } catch (e: any) {
                    setShowToast(e);
                } finally {
                    setSubmitting(false);
                    W.current = false;
                }
            }
        },
        [props.nosubscribe, subscription],
    );

    return (
        <>
            <FmToast visible={!!showToast} seconds={3}>
                {showToast}
            </FmToast>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validate={handleValidate}
                validateOnChange
                validateOnBlur
            >
                {({ values, setFieldValue, errors, isSubmitting }: any) => (
                    <Form className={stl["from_wrap"]}>
                        {pathname.includes("/customershow") ? (
                            <p className={stl["code-form-title"]}>
                                {t("common:SIGN_UP_FOR_MORE")}
                            </p>
                        ) : (
                            <>
                                {title ? (
                                    <p className={stl["code-form-title"]}>
                                        {title}
                                    </p>
                                ) : (
                                    <p
                                        className={stl["code-form-title"]}
                                        dangerouslySetInnerHTML={{
                                            __html: isShowTime
                                                ? t("common:JoinUsGetEarly")
                                                : t("common:JoinUsGet15Off"),
                                        }}
                                    ></p>
                                )}
                            </>
                        )}

                        <div className={stl["subscrib-container"]}>
                            <div className={stl["code-form-container"]}>
                                <FmInput
                                    className={classnames(
                                        stl["code-form-code"],
                                    )}
                                    helperTextClassName={stl["input-error"]}
                                    classes={{
                                        input: classnames(
                                            stl["input-content"],
                                            {
                                                [stl["input-error-border"]]:
                                                    !!errors.email?.length,
                                            },
                                        ),
                                        root: classnames(
                                            stl["input-root"],
                                            "input-root",
                                        ),
                                    }}
                                    type="email"
                                    name="email"
                                    onChange={(e) => {
                                        setFieldValue("email", e.target.value);
                                    }}
                                    placeholder={t(
                                        "common:EnterYourEmailAddressToGetCode",
                                    )}
                                    defaultValue={values.email}
                                    style={{
                                        borderColor: `${bg} !important`,
                                        
                                    }}
                                />
                                <FmButton
                                    className={classnames(
                                        stl["code-form-btn"],
                                        stl["subscribe-btn"],
                                        {
                                            [stl["code-form-btn-loading"]]:
                                                isSubmitting,
                                        },
                                    )}
                                    type="submit"
                                    disabled={isSubmitting}
                                    style={{
                                        background: `${bg ?? variable.main}`,
                                        padding: " 0.22rem !important",
                                    }}
                                    size={"large"}
                                >
                                    {t("common:Subscribe")}
                                </FmButton>
                            </div>
                            <p
                                className={classnames(stl["input-error"], {
                                    ["hidden"]: !errors.email?.length,
                                })}
                            >
                                {errors.email}
                            </p>
                        </div>

                        {!props.nosubscribe && (
                            <FmCheckbox
                                dataSource={[
                                    {
                                        label: (
                                            <p onClick={openModal}>
                                                {t("common:updateMe")}
                                            </p>
                                        ),
                                        value: "update",
                                        // formControlLabelClassName:
                                        // stl["checkbox-subscribe-label"],
                                    },
                                ]}
                                values={values.sub}
                                onChange={(value) => {
                                    setFieldValue("sub", value);
                                }}
                                formControlClassName={classnames(
                                    stl["subscription-container-formcontrol"],
                                )}
                                className={classnames(
                                    stl["subscription-container"],
                                    "subscription-container",
                                )}
                                noDefaultInteraction
                            />
                        )}
                        {is_cms && imgs && (
                            <FmLink
                                className={classnames(
                                    stl["image-container"],
                                    "image-container",
                                    {
                                        [classnames(
                                            stl["image-content"],
                                            stl["no-link-image"],
                                        )]: !link,
                                    },
                                )}
                                href={link}
                            >
                                <FmImage
                                    className={classnames(stl["imgs-cms"], {
                                        [classnames(
                                            stl["image-content"],
                                            "image-content",
                                        )]: link,
                                    })}
                                    image={imgs}
                                />
                            </FmLink>
                        )}
                        <FmDrawer
                            anchor="bottom"
                            open={modalStateOpen}
                            headerChildren={t("account:Newsletters.whatWould")}
                            onClose={closeModal}
                            className={stl["subsctiption-dialog-container"]}
                            childrenClassName={classnames(
                                stl["suscription-dialog"],
                                "subscription-dialog",
                            )}
                            closeable
                            closeablePosition="right"
                        >
                            <UpdateForm
                                onClose={closeModal}
                                subscription={subscription}
                                updateSub={(data: number[]) => {
                                    updateSubscription(data);
                                }}
                            />
                        </FmDrawer>
                        <FmDrawer
                            anchor="bottom"
                            closeable
                            closeablePosition="right"
                            open={values.thanksModalOpen}
                            onClose={(e) =>
                                setFieldValue("thanksModalOpen", false)
                            }
                            footer={
                                <FmButton
                                    onClick={() =>
                                        setFieldValue("thanksModalOpen", false)
                                    }
                                    variant={"outlined"}
                                    size={"large"}
                                    fullWidth
                                    style={{ marginTop: "0.4rem" }}
                                >
                                    Ok
                                </FmButton>
                            }
                            headerChildren={t("common:ThankForJoin")}
                            childrenClassName={stl["success-subscibe-content"]}
                        >
                            <>
                                <div className={stl["content"]}>
                                    {isShowTime
                                        ? t("common:Enjoy_40_Off_Frames")
                                        : t("common:Enjoy_15_Off_Frames")}
                                </div>
                                <div className={stl["content"]}>
                                    {t("common:Code")}: {values.user.coupon}
                                </div>
                                <div className={stl["content"]}>
                                    {t("common:Expires")}:
                                    {values.user.expireDate}
                                </div>
                                <FmLink
                                    target="_blank"
                                    href={
                                        url[site]
                                            ? url[site]
                                            : `/all-products.html`
                                    }
                                    className={stl["link-choose-glasses-now"]}
                                >
                                    {t("common:Choose_glasses_now")}
                                </FmLink>
                            </>
                        </FmDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default GetCodeForm;
